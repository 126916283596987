<template>
  <div class="container">
    <div>
      <div class="div3">
        <img :src="data.pic" />
      </div>
      <div class="one">{{data.title}}</div>
      <p class="two" v-html="data.content">{{data.content}}</p>
    </div>
  </div>
</template>

<script>
  import {getEnterpriseList} from "../../../api/zoujindaweijia"
  export default {
    name: "Hui",
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getEnterpriseList().then(res=>{
        // console.log(res.data[2]);
        let data = res.data[2];
        // console.log(data);
        this.data = data;
      })

    }
  }
</script>

<style scoped>
  .container{
    height: 52.8646vw;
  }
  .div3{
    position: absolute;
    margin-left:12.5vw ;
    margin-top: 6vw;
  }
  img{
    width: 20vw;
    /*height: 24.8438vw;*/

  }
  .one{
    position: absolute;
    margin-left: 42.2396vw;
    /*margin-top: 5vw;*/
    width: 20vw;
    /*height: 33px;*/
    font-size: 2.2vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #000000;
  }
  .two{
    position: absolute;
    width: 48.7917vw;
    /*height: 15.9896vw;*/
    font-size: 1.0417vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    margin-left: 42.1875vw;
    margin-top: 6vw;
  }

</style>